import "./DbrdDangerousGoods.scss";

import React, { Component } from "react";

import crossfilter from "crossfilter2";
import PropTypes from "prop-types";
import { AVIColorScales } from "../../chart-components/AVIColorScales";
import { GroupUtils } from "../../chart-components/ChartUtils/GroupUtils";
import { BarChartOrdinal } from "../../chart-components/Charts/BarChartOrdinal";
import { DataTable } from "../../chart-components/Charts/DataTable";
import { RowChart } from "../../chart-components/Charts/RowChart";
import { AVIDashboard } from "../../chart-components/Layout/AVIDashboard";
import { AVIRow } from "../../chart-components/Layout/AVIRow";
import { InfoRow } from "../../chart-components/Layout/InfoRow";
import { SimpleTimespan } from "../../filters/SimpleDateTimeControl/SimpleTimespan";
import withRouter from "../../hocs/withRouter";
import { DataLoader } from "../../ui-components/DataLoader/DataLoader";
import { Loader } from "../../ui-components/Loader/Loader";

class DbrdDangerousGoodsLocal extends Component<any, any> {
  static dashboardRoute = "dangerous-goods";

  static dashboardFilters(filter, setFilter, passlines, renderSelection) {
    return {
      helpMessage: "Dashboardet farlig goods genererer mye data",
      controls: [
        <SimpleTimespan
          key="flt-simple-timespan"
          filter={filter}
          setFilter={setFilter}
          predefinedRanges
        />,
      ],
    };
  }

  static dashboardSettings() {
    return {};
  }

  static dashboardValidation(filter) {
    if (filter.fromTime && filter.toTime) {
      return true;
    } else {
      return false;
    }
  }

  static propTypes = {
    location: PropTypes.shape({
      state: PropTypes.shape({
        fromTime: PropTypes.string.isRequired,
        toTime: PropTypes.string.isRequired,
      }),
    }),
  };

  constructor(props) {
    super(props);
    this.state = {
      chartData: null,
    };

    this.reportProgress = this.reportProgress.bind(this);
  }

  componentDidMount() {
    const { fromTime, toTime } = this.props.location.state;
    try {
      DataLoader.getApi(
        `/api/voyage/dangerous-goods/by-voyage-segments/${fromTime}/${toTime}`,
        {},
        this.reportProgress
      )
        .then((res) => {
          if (!res.success) throw new Error("Error loading analytics data");

          if (Array.isArray(res.data) && res.data.length > 0) {
            res.data.forEach((element) => {
              element.created = new Date(element.created);
            });
            // console.log(res.data[0]);
            this.setState({
              chartData: crossfilter(res.data),
            });
          }
        })
        .catch((error) => {
          console.warn(error);
          this.setState({
            chartData: crossfilter([]),
          });
        });
    } catch (error) {
      this.setState({
        chartData: crossfilter([]),
      });
    }
  }

  reportProgress(progressData) {
    this.setState({
      progressData: progressData,
    });
  }

  render() {
    const { chartData, progressData } = this.state;
    const { fromTime, toTime } = this.props.location.state;

    var dashboardTitle = "Farlig gods";

    if (!chartData || chartData.size() < 1) {
      return <Loader chartData={chartData} progressData={progressData} />;
    }

    var dimSegment = chartData.dimension((d) => [d.origin, d.destination]);

    function isNorway(ct) {
      return ["NO", "SJ"].indexOf(ct) > -1;
    }

    let dimIntlDomestic = chartData.dimension((d) => {
      if (isNorway(d.ocountry) && isNorway(d.dcountry)) {
        return "Innenrikstrafikk, inkludert Svalbard og Jan Mayen";
      } else if (isNorway(d.dcountry)) {
        return "Trafikk til Norge fra utlandet";
      } else if (!isNorway(d.dcountry)) {
        return "Trafikk til utlandet fra Norge";
      } else {
        return "Øvrig";
      }
    });
    let grpVoyagesByIntlDomestic = dimIntlDomestic.group().reduceCount();

    let dimDangerousGoodsType = chartData.dimension(
      (d) => d.dangerousandpollutionggoodstype
    );
    let grpVoyagesByDangerousGoodsType = dimDangerousGoodsType
      .group()
      .reduceCount();

    let dimImoHazardClassName = chartData.dimension((d) =>
      d.imohazardclassname ? "" + d.imohazardclassname : "Ikke oppgitt"
    );
    let grpVoyagesByImoHazardClassName = dimImoHazardClassName
      .group()
      .reduceCount();

    var dimOrigin = chartData.dimension((d) => d.origin);
    var grpVoyagesByOrigin = dimOrigin.group().reduceCount();

    var dimDestination = chartData.dimension((d) => d.destination);
    var grpVoyagesByDestination = dimDestination.group().reduceCount();

    return (
      <div className="AppView">
        <AVIDashboard
          title={dashboardTitle}
          desc={`Data om seilas med farlig gods i tidsperioden fra ${fromTime} til ${toTime}`}
          useFlex
          cmsSlug={DbrdDangerousGoodsLocal.dashboardRoute}
          cfilter={chartData}
          filter={this.props.location.state}
        >
          <AVIRow>
            <RowChart
              chartTitle="Seilas med farlig gods (innenriks/utenriks)"
              width={2}
              height={0.8}
              dimension={dimIntlDomestic}
              group={grpVoyagesByIntlDomestic}
              filterPrefix="Internasjonale seilas"
              useFlex
              colors={AVIColorScales.colorScaleKyv2024}
            />
          </AVIRow>
          <AVIRow>
            <RowChart
              chartTitle="Seilas med farlig gods etter avgangslokasjon"
              width={2}
              height={2}
              dimension={dimOrigin}
              group={GroupUtils.RemoveEmptyBinsTopN(grpVoyagesByOrigin, 15)}
              filterPrefix="Side"
              colors={AVIColorScales.colorScaleKyv2024}
              useFlex
            />
            <RowChart
              chartTitle="Antall seilas med farlig gods etter ankomstlokasjon"
              width={2}
              height={2}
              dimension={dimDestination}
              group={GroupUtils.RemoveEmptyBinsTopN(
                grpVoyagesByDestination,
                15
              )}
              colors={AVIColorScales.colorScaleKyv2024}
              filterPrefix="Ankomstlokasjon"
              useFlex
            />
          </AVIRow>
          <AVIRow>
            <BarChartOrdinal
              group={GroupUtils.RemoveEmptyBinsTopN(
                grpVoyagesByDangerousGoodsType,
                25
              )}
              dimension={dimDangerousGoodsType}
              chartTitle="Antall seilas etter farlig godskategori (25 største)"
              width={4}
              useFlex
              height={1}
              margins={{ left: 50, bottom: 50 }}
              ordering={(d) => -d.value}
              xAxisLabel="Type farlig gods"
              filterPrefix="Type farlig gods"
              rotateXAxisLabels={-15}
              info="This enumeration presents the general categories of Hazardous cargo, according to the International Maritime Dangerous Goods (IMDG) code. For additional information about IBC, IGC and INF (IBC - Intermediate Bulk Container, IGC - International Gas Carrier and INF - Irradiated Nuclear Fuel) contact the International Maritime Organization (IMO)"
              colors={AVIColorScales.colorScaleKyv2024Mono}
            />
            <BarChartOrdinal
              group={GroupUtils.RemoveEmptyBinsTopN(
                grpVoyagesByImoHazardClassName,
                25
              )}
              dimension={dimImoHazardClassName}
              chartTitle="Antall seilas etter IMO klassifisering (25 største)"
              width={4}
              useFlex
              height={1}
              margins={{ left: 50, bottom: 50 }}
              ordering={(d) => -d.value}
              xAxisLabel="IMO klassifisering for farlig gods"
              filterPrefix="IMO klasse"
              rotateXAxisLabels={-25}
              colors={AVIColorScales.colorScaleKyv2024Mono}
            />
          </AVIRow>
          <AVIRow>
            <InfoRow title="Hva betyr IMDG, IBC, IGC i diagrammet over?">
              <p>Her er en kort forklaring av viktige kodene over:</p>
              <ul>
                <li>
                  <strong>
                    IMDG (International Maritime Dangerous Goods Code):
                  </strong>{" "}
                  Regulerer sikker transport av farlig gods til sjøs.
                </li>
                <li>
                  <strong>IBC (International Bulk Chemical Code):</strong> Krav
                  til skip som frakter farlige kjemikalier i bulk.
                </li>
                <li>
                  <strong>IGC (International Gas Carrier Code):</strong>{" "}
                  Standarder for skip som frakter flytende gasser i bulk.
                </li>
                <li>
                  <strong>MARPOL Annex I:</strong> Forebygger forurensning med
                  olje fra skip.
                </li>
                <li>
                  <strong>
                    IMSBC (International Maritime Solid Bulk Cargoes Code):
                  </strong>{" "}
                  Forskrifter for sikker transport av faste bulkvarer til sjøs.
                </li>
              </ul>
            </InfoRow>
            <InfoRow title="Hva er IMO klassifisering for farlig gods?">
              <p>
                IMO (International Maritime Organization) klassifiserer farlig
                gods i ni klasser basert på deres farlige egenskaper:
              </p>
              <ol>
                <li>
                  <strong>Eksplosiver:</strong> Materialer som kan eksplodere,
                  som fyrverkeri og dynamitt.
                </li>
                <li>
                  <strong>Gasser:</strong> Brennbare, giftige eller oksiderende
                  gasser, som propan og klorgass.
                </li>
                <li>
                  <strong>Brennbare væsker:</strong> Væsker som lett antennes,
                  som bensin og alkohol.
                </li>
                <li>
                  <strong>Brennbare faste stoffer:</strong> Stoffer som er
                  brennbare, som svovel og fosfor.
                </li>
                <li>
                  <strong>Oksiderende stoffer og organiske peroksider:</strong>{" "}
                  Materialer som kan forårsake brann, som hydrogenperoksid.
                </li>
                <li>
                  <strong>Giftige og smittefarlige stoffer:</strong> Skadelige
                  materialer, som cyanider og plantevernmidler.
                </li>
                <li>
                  <strong>Radioaktive materialer:</strong> Materialer som avgir
                  ioniserende stråling, som uran.
                </li>
                <li>
                  <strong>Etsende stoffer:</strong> Stoffer som kan forårsake
                  skade, som svovelsyre.
                </li>
                <li>
                  <strong>Diverse farlige stoffer:</strong> Andre farlige
                  materialer, som litiumbatterier.
                </li>
              </ol>
            </InfoRow>
          </AVIRow>
          <AVIRow>
            <DataTable
              chartTitle="Seilas med farlig gods"
              columns={[
                {
                  label: "År",
                  format: (d) => d.year,
                },
                {
                  label: "Måned",
                  format: (d) => d.month,
                },
                {
                  label: "Avgangslokasjon",
                  format: (d) => d.origin,
                },
                {
                  label: "Ankomstlokasjon",
                  format: (d) => d.destination,
                },
                {
                  label: "Skipstype",
                  format: (d) => d.type,
                },
                {
                  label: "Flaggstat",
                  format: (d) => d.flag,
                },
                {
                  label: "Farlig gods kategori",
                  format: (d) => d.dangerousandpollutionggoodstype,
                },
                {
                  label: "IMO klassifisering",
                  format: (d) => d.imohazardclassname,
                },
                {
                  label: "Farlig gods",
                  format: (d) => d.textualreference,
                },
                {
                  label: "Mengde",
                  format: (d) => d.netquantity,
                },
                {
                  label: "Enhet",
                  format: (d) => d.netunit,
                },
              ]}
              dimension={dimSegment}
            />
          </AVIRow>
        </AVIDashboard>
      </div>
    );
  }
}

export const DbrdDangerousGoods = withRouter(DbrdDangerousGoodsLocal);

export default DbrdDangerousGoods;
