import "../AVIChartCommon.scss";

import React, { Component } from "react";

import { AVIChartTile } from "../Layout/AVIChartTile";
import { BaseMixin } from "../Mixins/BaseMixin";
import { CapMixin } from "../Mixins/CapMixin";
import { ColorMixin } from "../Mixins/ColorMixin";
import { CoordinateGridMixin } from "../Mixins/CoordinateGridMixin";
import { MarginsMixin } from "../Mixins/MarginsMixin";
import { NumUtils } from "./../ChartUtils/NumUtils";
import PropTypes from "prop-types";
import { StackUtils } from "../ChartUtils/StackUtils";
import { TileMixin } from "../Mixins/TileMixin";
import * as dc from "dc";
import { AVIColorScales } from "../AVIColorScales";

export class BarChart extends Component {
  static propTypes = {
    gap: PropTypes.number,
    alwaysUseRounding: PropTypes.bool,
    centerBar: PropTypes.bool,
    outerPadding: PropTypes.number,
    barPadding: PropTypes.number,
    group: PropTypes.object,
    groupName: PropTypes.string,
    groupSelectionFunction: PropTypes.func,
    stack: PropTypes.array,
    chartTitle: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    renderLabel: PropTypes.bool,
    ordering: PropTypes.func,
    rotateXAxisLabels: PropTypes.number,
    label: PropTypes.func,
    margins: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    keyAccessor: PropTypes.func,
    useFlex: PropTypes.bool,
    colors: PropTypes.func,
    extraMenuItems: PropTypes.array,
  };

  static defaultProps = {
    gap: 2,
    round: (n) => Math.floor(n),
    alwaysUseRounding: true,
    centerBar: false,
    outerPadding: 0.5,
    barPadding: 0,
    renderLabel: true,
    rotateXAxisLabels: 0,
    label: (d) => NumUtils.integer(d.y1),
    valueAccessor: (d) => d.value,
    keyAccessor: (d) => d.key,
    useFlex: false,
    colors: AVIColorScales.colorScaleKyv2024Mono,
    extraMenuItems: undefined
  };

  constructor(props) {
    super(props);
    this.chartRef = React.createRef();
    this.chart = null;
    this.getChart = this.getChart.bind(this);
  }

  getChart() {
    return this.chart;
  }

  componentDidMount() {
    const {
      group,
      groupName,
      groupSelectionFunction,
      stack,
      gap,
      alwaysUseRounding,
      centerBar,
      outerPadding,
      barPadding,
      renderLabel,
      ordering,
      rotateXAxisLabels,
      label,
    } = this.props;

    const { margins, ...otherProps } = this.props;
    const chartProps = { margins: margins, ...otherProps };

    this.chart = dc.barChart(this.chartRef.current);

    BaseMixin(this.chart, chartProps);
    CapMixin(this.chart, chartProps);
    ColorMixin(this.chart, chartProps);
    MarginsMixin(this.chart, chartProps);
    CoordinateGridMixin(this.chart, chartProps);
    TileMixin(this.chart, chartProps);

    if (group && groupName && groupSelectionFunction && stack) {
      // Add first series
      this.chart.group(group, groupName, groupSelectionFunction);

      // Stack additional series
      for (var s = 1; s < stack.length; s++) {
        this.chart.stack(group, stack[s], StackUtils.selStack(stack[s]));
      }
    }

    this.chart
      .gap(gap)
      .alwaysUseRounding(alwaysUseRounding)
      .centerBar(centerBar)
      .outerPadding(outerPadding)
      .renderLabel(renderLabel)
      .label(label);

    if (barPadding > 0) {
      this.chart.barPadding(barPadding);
    }

    if (typeof ordering === "function") {
      this.chart.ordering(ordering);
    }

    if (rotateXAxisLabels !== 0) {
      this.chart.on("renderlet", (chart) => {
        chart
          .selectAll("g.x text")
          .attr("transform", `translate(-10,10) rotate(${rotateXAxisLabels})`);
      });
    }

    this.chart.render();
  }

  render() {
    const {
      chartTitle,
      width,
      height,
      useFlex,
      extraMenuItems = undefined,
    } = this.props;
    return (
      <AVIChartTile
        title={chartTitle}
        getChart={this.getChart}
        width={width}
        height={height}
        useFlex={useFlex}
        extraMenuItems={extraMenuItems}
      >
        <div className="avi-chart" ref={this.chartRef} />
      </AVIChartTile>
    );
  }
}
