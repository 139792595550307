import "./DbrdAnalyticsWebServices.scss";

import React, { Component } from "react";

import crossfilter from "crossfilter2";
import PropTypes from "prop-types";
import { GroupUtils } from "../../chart-components/ChartUtils/GroupUtils";
import { TimeUtils } from "../../chart-components/ChartUtils/TimeUtils";
import { BarChartDate } from "../../chart-components/Charts/BarChartDate";
import { HeatMap } from "../../chart-components/Charts/HeatMap";
import { RowChart } from "../../chart-components/Charts/RowChart";
import { AVIDashboard } from "../../chart-components/Layout/AVIDashboard";
import { AVIRow } from "../../chart-components/Layout/AVIRow";
import { SimpleTimespan } from "../../filters/SimpleDateTimeControl/SimpleTimespan";
import withRouter from "../../hocs/withRouter";
import {
  DashboardFilter,
  SetDashboardFilterFunc,
} from "../../types/DashboardFilter";
import { DataLoader } from "../../ui-components/DataLoader/DataLoader";
import { Loader } from "../../ui-components/Loader/Loader";

class DbrdAnalyticsWebServicesLocal extends Component<any, any> {
  static dashboardRoute = "analytics-webservices";

  static dashboardFilters(
    filter: DashboardFilter,
    setFilter: SetDashboardFilterFunc
  ) {
    return {
      helpMessage: "Analytics genererer mye data",
      controls: [
        <SimpleTimespan
          filter={filter}
          setFilter={setFilter}
          key="flt-timespan"
          predefinedRanges
        />,
      ],
    };
  }

  static dashboardSettings() {
    return {};
  }

  static dashboardValidation(filter) {
    if (filter.fromTime && filter.toTime) {
      return true;
    } else {
      return false;
    }
  }

  static propTypes = {
    location: PropTypes.shape({
      state: PropTypes.shape({
        fromTime: PropTypes.string.isRequired,
        toTime: PropTypes.string.isRequired,
      }),
    }),
  };

  constructor(props) {
    super(props);
    this.state = {
      chartData: null,
    };

    this.reportProgress = this.reportProgress.bind(this);
  }

  componentDidMount() {
    const { fromTime, toTime } = this.props.location.state;
    try {
      DataLoader.postApi(
        `/api/analytics/aisstats/${fromTime}/${toTime}`,
        {},
        this.reportProgress
      )
        .then((res) => {
          // Check that all responses are successful
          if (!res.success) throw new Error("COuld not load statistics");

          if (Array.isArray(res.data) && res.data.length > 0) {
            res.data.forEach((element) => {
              element.created = new Date(element.created);
            });
            this.setState({
              chartData: crossfilter(res.data),
            });
          } else {
            throw new Error("No data returned");
          }
        })
        .catch((error) => {
          console.warn(error);
          this.setState({
            chartData: crossfilter([]),
          });
        });
    } catch (error) {
      console.warn(error);
      this.setState({
        chartData: crossfilter([]),
      });
    }
  }

  reportProgress(progressData) {
    this.setState({
      progressData: progressData,
    });
  }

  render() {
    const { chartData, progressData } = this.state;
    const { state: filter } = this.props.location;

    var dashboardTitle = "Analytics for Web Services";

    if (!chartData || chartData.size() === 0) {
      return <Loader chartData={chartData} progressData={progressData} />;
    }

    var dimHourOfWeekday = chartData.dimension((d) => [
      d.created.getDay(),
      d.created.getHours(),
    ]);
    var grpHourOfWeekday = dimHourOfWeekday.group().reduceCount();

    var dimDate = chartData.dimension((d) => {
      var t = new Date(d.created);
      t.setHours(0, 0, 0, 0);
      return t;
    });
    var grpPageLoadsByDate = dimDate.group().reduceCount();

    var dimPath = chartData.dimension((d) => d.route);
    var grpPageLoadsByPath = dimPath.group().reduceCount();

    var dimRole = chartData.dimension((d) => d.role);
    var grpPageLoadsByRole = dimRole.group().reduceCount();

    return (
      <div className="AppView">
        <AVIDashboard
          title={dashboardTitle}
          desc={"Bruksdata for Kystdatahuset"}
          cfilter={chartData}
          useFlex
          cmsSlug={DbrdAnalyticsWebServicesLocal.dashboardRoute}
          filter={filter}
        >
          <AVIRow>
            <HeatMap
              chartTitle="API-kall etter time i døgnet og dag i uken"
              dimension={dimHourOfWeekday}
              group={grpHourOfWeekday}
              width={4}
              height={2}
              colOrdering={TimeUtils.localeWeekDayAbbSortFunction}
              colsLabel={(d) => HeatMap.weekDaysJsNo[d]}
              rowsLabel={(d) => HeatMap.hoursOfDay[d]}
              useFlex
            />
          </AVIRow>
          <AVIRow>
            <BarChartDate
              chartTitle="Apikall etter dato"
              dimension={dimDate}
              group={grpPageLoadsByDate}
              // ordering={d => shipLengthLabels.indexOf(d.key)}
              width={4}
              height={1.5}
              filterPrefix="Dato"
              useFlex
            />
          </AVIRow>
          <AVIRow>
            <RowChart
              chartTitle="API kall etter web service (15 mest besøkte)"
              width={2}
              height={2}
              dimension={dimPath}
              group={GroupUtils.RemoveEmptyBinsTopN(grpPageLoadsByPath, 15)}
              filterPrefix="Side"
              useFlex
            />
            <RowChart
              chartTitle="API kall etter rolle"
              width={2}
              height={2}
              dimension={dimRole}
              group={GroupUtils.RemoveEmptyBins(grpPageLoadsByRole)}
              filterPrefix="Rolle"
              useFlex
            />
          </AVIRow>
        </AVIDashboard>
      </div>
    );
  }
}

export const DbrdAnalyticsWebservices = withRouter(
  DbrdAnalyticsWebServicesLocal
);

export default DbrdAnalyticsWebservices;
