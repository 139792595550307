import React, { Component } from 'react';

import { AVIChartTile } from '../Layout/AVIChartTile';
import { BaseMixin } from '../Mixins/BaseMixin';
import { CapMixin } from '../Mixins/CapMixin';
import { ColorMixin } from '../Mixins/ColorMixin';
import PropTypes from 'prop-types';
import { TileMixin } from '../Mixins/TileMixin';
import * as dc from 'dc';
import { AVIColorScales } from '../AVIColorScales';

export class PieChart extends Component {
  static propTypes = {
    drawPaths: PropTypes.bool,
    radius: PropTypes.number,
    innerRadius: PropTypes.number,
    slicesCap: PropTypes.number,
    externalLabels: PropTypes.bool,
    minAngleForLabel: PropTypes.number,
    externalRadiusPadding: PropTypes.number,
    chartTitle: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    onFiltered: PropTypes.func,
    colors: PropTypes.func,
    // eslint-disable-next-line react/no-unused-prop-types
    ordering: PropTypes.func
  };

  static defaultProps = {
    drawPaths: false,
    radius: undefined,
    innerRadius: 0,
    slicesCap: 15,
    externalLabels: true,
    minAngleForLabel: 0.25,
    externalRadiusPadding: 50,
    onFiltered: undefined,
    ordering: d => -d.value,
    colors: AVIColorScales.colorScaleKyv2024 
  };

  constructor (props) {
    super(props);
    this.chartRef = React.createRef();
    this.chart = null;
    this.getChart = this.getChart.bind(this);
  }

  getChart () {
    return this.chart;
  }

  componentDidMount () {
    this.chart = dc.pieChart(this.chartRef.current);

    BaseMixin(this.chart, this.props);
    CapMixin(this.chart, this.props);
    ColorMixin(this.chart, this.props);
    TileMixin(this.chart, this.props);

    const {
      drawPaths,
      radius,
      innerRadius,
      slicesCap,
      externalLabels,
      minAngleForLabel,
      externalRadiusPadding,
      onFiltered
    } = this.props;

    this.chart
      .drawPaths(drawPaths)
      .innerRadius(innerRadius)
      .slicesCap(slicesCap)
      .minAngleForLabel(minAngleForLabel)
      .externalLabels(externalLabels)
      .externalRadiusPadding(externalRadiusPadding)
      .label(d => {
        return d.key + ' (' + d.value + ')';
      });

    if (onFiltered && typeof onFiltered === 'function') {
      this.chart.on('filtered', onFiltered);
    }

    if (radius) {
      this.chart.radius(radius);
    }

    this.chart.render();
  }

  render () {
    return (
      <AVIChartTile
        title={this.props.chartTitle}
        getChart={this.getChart}
        width={this.props.width}
        height={this.props.height}
      >
        <div className='avi-chart' ref={this.chartRef} />
      </AVIChartTile>
    );
  }
}
