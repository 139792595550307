import "./DbrdAnalytics.scss";

import React, { Component } from "react";

import crossfilter from "crossfilter2";
import { GroupUtils } from "../../chart-components/ChartUtils/GroupUtils";
import { TimeUtils } from "../../chart-components/ChartUtils/TimeUtils";
import { BarChartDate } from "../../chart-components/Charts/BarChartDate";
import { HeatMap } from "../../chart-components/Charts/HeatMap";
import { RowChart } from "../../chart-components/Charts/RowChart";
import { AVIDashboard } from "../../chart-components/Layout/AVIDashboard";
import { AVIRow } from "../../chart-components/Layout/AVIRow";
import { SimpleTimespan } from "../../filters/SimpleDateTimeControl/SimpleTimespan";
import withRouter from "../../hocs/withRouter";
import { AnalyticsLogEntry } from "../../types/Analytics";
import {
  DashboardFilter,
  SetDashboardFilterFunc,
} from "../../types/DashboardFilter";
import { WebServiceResponse } from "../../types/WebServiceResponse";
import { DataLoader } from "../../ui-components/DataLoader/DataLoader";
import { Loader } from "../../ui-components/Loader/Loader";

type DateFromToFilterProps = {
  location: any;
};

type DateFromToFilterState = {
  chartData: any;
  progressData?: any;
};

class DbrdAnalyticsBase extends Component<
  DateFromToFilterProps,
  DateFromToFilterState
> {
  static dashboardRoute = "analytics";

  static dashboardFilters(
    filter: DashboardFilter,
    setFilter: SetDashboardFilterFunc
  ) {
    return {
      helpMessage:
        "Analytics genererer mye data så det kan vere lurt å velge relativt korte tidsperiode",
      controls: [
        <SimpleTimespan
          key="flt-tf-dt-2"
          setFilter={setFilter}
          filter={filter}
          predefinedRanges
        />,
      ],
    };
  }

  static dashboardSettings() {
    return {};
  }

  static dashboardValidation(filter: DashboardFilter) {
    if (filter.fromTime && filter.toTime) {
      return true;
    } else {
      return false;
    }
  }

  constructor(props: DateFromToFilterProps) {
    super(props);
    this.state = {
      chartData: null,
    };

    this.reportProgress = this.reportProgress.bind(this);
  }

  componentDidMount() {
    if (!this.props.location?.state) return;

    try {
      const { fromTime, toTime } = this.props.location.state;
      DataLoader.postApi(
        `/api/analytics/log/read/kystdatahuset/${fromTime.split("T")[0]}/${
          toTime.split("T")[0]
        }`,
        {},
        this.reportProgress
      )
        .then((res: WebServiceResponse<AnalyticsLogEntry[]>) => {
          // Check that all responses are successful
          if (!res.success) throw new Error("Could not load analytics data");
          // console.log(res.data[0]);
          this.setState({
            chartData: crossfilter(res.data),
          });
        })
        .catch((error) => {
          console.warn(error);
          this.setState({
            chartData: crossfilter([]),
          });
        });
    } catch (error) {
      console.warn("error");
      this.setState({
        chartData: crossfilter([]),
      });
    }
  }

  reportProgress(progressData: any) {
    this.setState({
      progressData: progressData,
    });
  }

  render() {
    const { chartData, progressData } = this.state;

    var dashboardTitle = "Analytics";

    if (!chartData || chartData.size() === 0) {
      return <Loader chartData={chartData} progressData={progressData} />;
    }

    var dimHourOfWeekday = chartData.dimension((d: AnalyticsLogEntry) => [
      d.weekday,
      d.hour,
    ]);
    var grpHourOfWeekday = dimHourOfWeekday.group().reduceCount();

    var dimDate = chartData.dimension(
      (d: AnalyticsLogEntry) => new Date(Date.UTC(d.year, d.month - 1, d.day))
    );
    var grpPageLoadsByDate = dimDate.group().reduceCount();

    var dimPath = chartData.dimension((d: AnalyticsLogEntry) => d.path);
    var grpPageLoadsByPath = dimPath.group().reduceCount();

    var dimRole = chartData.dimension((d: AnalyticsLogEntry) => d.role);
    var grpPageLoadsByRole = dimRole.group().reduceCount();

    return (
      <div className="AppView">
        <AVIDashboard
          title={dashboardTitle}
          desc={"Bruksdata for Kystdatahuset"}
          cmsSlug={DbrdAnalyticsBase.dashboardRoute}
          useFlex
          cfilter={chartData}
          filter={this.props.location.state}
        >
          <AVIRow>
            <HeatMap
              chartTitle="Sidevisninger etter time i døgnet og dag i uken"
              dimension={dimHourOfWeekday}
              group={grpHourOfWeekday}
              width={4}
              height={2}
              colOrdering={TimeUtils.localeWeekDayAbbSortFunction}
              colsLabel={(d: any) => HeatMap.weekDaysNo[d - 1]}
              rowsLabel={(d: any) => HeatMap.hoursOfDay[d]}
              useFlex
            />
          </AVIRow>
          <AVIRow>
            <BarChartDate
              chartTitle="Sidevisninger etter dato"
              dimension={dimDate}
              group={grpPageLoadsByDate}
              width={4}
              height={1.5}
              filterPrefix="Dato"
              useFlex
            />
          </AVIRow>
          <AVIRow>
            <RowChart
              chartTitle="Sidevisninger etter side (15 mest besøkte)"
              width={2}
              height={2}
              dimension={dimPath}
              group={GroupUtils.RemoveEmptyBinsTopN(grpPageLoadsByPath, 15)}
              filterPrefix="Side"
              useFlex
            />
            <RowChart
              chartTitle="Sidevisninger etter rolle"
              width={2}
              height={2}
              dimension={dimRole}
              group={GroupUtils.RemoveEmptyBins(grpPageLoadsByRole)}
              filterPrefix="Rolle"
              useFlex
            />
          </AVIRow>
        </AVIDashboard>
      </div>
    );
  }
}

export const DbrdAnalytics = withRouter(DbrdAnalyticsBase);

export default DbrdAnalytics;
