import "./ModalLayout.scss";

import React from "react";
import { Container } from "react-bootstrap";
import { ModalLayoutBackButton } from "./ModalLayoutBackButton";
import { ModalLayoutHeader } from "./ModalLayoutHeader";

export function DashboardLayout({ children }: React.PropsWithChildren) {

  return (
    <>
      <ModalLayoutHeader />
      <ModalLayoutBackButton />
      <Container fluid className="px-0">
        {children}
      </Container>
    </>
  );
}

export default DashboardLayout;
