import "./DbrdShip.scss";

import React, { Component } from "react";

import crossfilter from "crossfilter2";
import PropTypes from "prop-types";
import { NumUtils } from "../../chart-components/ChartUtils/NumUtils";
import { AVICol } from "../../chart-components/Layout/AVICol";
import { AVIDashboard } from "../../chart-components/Layout/AVIDashboard";
import { AVIRow } from "../../chart-components/Layout/AVIRow";
import { AVITileFlex } from "../../chart-components/Layout/AVITileFlex";
import { FilterShipName } from "../../filters/FilterShipName";
import withRouter from "../../hocs/withRouter";
import { DataLoader } from "../../ui-components/DataLoader/DataLoader";
import { Loader } from "../../ui-components/Loader/Loader";
import { ShipDataPropertyMapping } from "./ShipDataPropertyMapping";
import {
  DashboardFilter,
  SetDashboardFilterFunc,
} from "../../types/DashboardFilter";

export type DbrdShipProps = any;
export type DbrdShipState = any;

class DbrdShipBase extends Component<DbrdShipProps, DbrdShipState> {
  static dashboardRoute = "ship";

  static dashboardFilters(
    filter: DashboardFilter,
    setFilter: SetDashboardFilterFunc
  ) {
    return {
      helpMessage:
        "Skriv hele eller deler av et navn på et skip. Du kan velge mer enn et skip om gangen. Når du har valgt et skip, begynn å skrive navnet på det neste så dukker det opp en liste over skip som matcher søkeuttrykket.",
      controls: (
        <FilterShipName
          key="flt"
          firstYear={2013}
          filter={filter}
          setFilter={setFilter}
        />
      ),
    };
  }

  static dashboardSettings() {
    return {
      filterControls: [],
      selectableLayer: null,
    };
  }

  static dashboardValidation(filter) {
    if (filter.shipIds) {
      return true;
    }
  }

  static propTypes = {
    location: PropTypes.shape({
      state: PropTypes.object.isRequired,
    }),
  };

  constructor(props) {
    super(props);
    this.state = {
      chartData: null,
      geoJsonData: null,
      locationInfo: null,
    };
  }

  elementIndex = 0;

  componentDidMount() {
    const { shipIds } = this.props.location.state;

    var mmsiIds: any[] = [];
    var imoIds: any[] = [];

    shipIds.forEach((o) => {
      mmsiIds.push(o.data.mmsi);
      imoIds.push(o.data.imo);
    });

    if (
      Array.isArray(mmsiIds) &&
      Array.isArray(imoIds) &&
      (mmsiIds.length > 0 || imoIds.length > 0)
    ) {
      var mergeRequests = [
        DataLoader.postApi("/api/ship/data/nsr/for-mmsis-imos", {
          MMSI: mmsiIds.filter((m) => m > 0),
          IMO: imoIds.filter((m) => m > 0),
        }),
        DataLoader.postApi("/api/ship/data/fairplay/for-mmsis-imos", {
          MMSI: mmsiIds.filter((m) => m > 0),
          IMO: imoIds.filter((m) => m > 0),
        }),
        DataLoader.postApi("/api/ship/data/shipinfo/for-mmsis-imos", {
          MMSI: mmsiIds.filter((m) => m > 0),
          IMO: imoIds.filter((m) => m > 0),
        }),
        DataLoader.postApi("/api/ship/combined/2/free-text", {
          freeText:
            mmsiIds.filter((m) => m > 0).join("|") +
            "|" +
            imoIds.filter((m) => m > 0).join("|"),
        }),
      ];
      Promise.all(mergeRequests)
        .then(
          (jsonResponses) => {
            // console.log(jsonResponses);
            var success = jsonResponses.some((r) => r.success === true);

            if (!success) {
              return;
            }

            var nsrShip = jsonResponses[0].data ?? [];
            var fairplay = jsonResponses[1].data ?? [];
            var shipinfo = jsonResponses[2].data ?? [];
            var combined = jsonResponses[3].data ?? [];

            this.setState({
              nsrShip,
              shipinfo,
              fairplay,
              combined,
            });
          },
          (error) => {
            error && console.error("Error occurred");
          }
        )
        .catch((error) => {
          console.warn(error);
          this.setState({
            chartData: crossfilter([]),
          });
        });
    } else {
      throw new Error("Missing dashboard selection parameters");
    }
  }

  getRows(
    row: { [key: string]: any },
    propMap: [[key: string, label: string]]
  ) {
    return Object.entries(propMap).map(([key, label]) => {
      if (row[key]) {
        let val = row[key];
        if (Array.isArray(val)) {
          val = val.join(", ");
        } else {
          val = NumUtils.isNumber(val)
            ? val % 1 !== 0
              ? NumUtils.decimal2(val)
              : NumUtils.integer(val)
            : val;
        }
        return (
          <tr key={this.elementIndex++}>
            <td>
              <div title={key}>{label}</div>
            </td>
            <td>{val}</td>
          </tr>
        );
      } else {
        return null;
      }
    });
  }

  makeTable(dataArray, titleKey, sortKey, descending, propMap) {
    titleKey = titleKey || 0;

    dataArray.sort((a, b) => {
      if (a[sortKey] > b[sortKey]) return descending ? -1 : 1;
      if (b[sortKey] > a[sortKey]) return descending ? 1 : -1;
      return 0;
    });

    return dataArray.map((dataRow) => {
      var properties = this.getRows(dataRow, propMap);
      return (
        <table className="shipEntry" key={this.elementIndex++}>
          <thead>
            <tr>
              <th colSpan={2}>
                <div title={titleKey}>{dataRow[titleKey]}</div>
              </th>
            </tr>
          </thead>
          <tbody>{properties}</tbody>
        </table>
      );
    });
  }

  render() {
    const { nsrShip, shipinfo, fairplay, combined } = this.state;

    if (!fairplay && !shipinfo && !nsrShip && !combined) {
      return <Loader chartData={undefined} />;
    }

    if (
      fairplay.length < 1 &&
      shipinfo.length < 1 &&
      nsrShip.length < 1 &&
      combined.length < 1
    ) {
      return <Loader chartData={crossfilter([])} />;
    }

    var fromNsrShip = this.makeTable(
      nsrShip,
      "shipname",
      "yearofbuild",
      true,
      ShipDataPropertyMapping.nsrShip
    );

    var fromFairplay = this.makeTable(
      fairplay,
      "shipname",
      "yearofbuild",
      true,
      ShipDataPropertyMapping.fairplay
    );

    var fromShipinfo = this.makeTable(
      shipinfo,
      "vessel_name",
      "built_year",
      true,
      ShipDataPropertyMapping.shipinfo
    );

    var fromCombined = this.makeTable(
      combined,
      "shipname",
      "yearofbuild",
      true,
      ShipDataPropertyMapping.combinedShip
    );

    var images = shipinfo.map((row) => {
      if (row.photo1 !== null && row.photo1.trim() !== "") {
        return (
          <AVITileFlex key={this.elementIndex++} title={row.vessel_name}>
            <p>Merk at dette skipet kan vere et annet enn det du søkte på dersom det tidligere har seilt med samme MMSI eller kallesignal</p>
            <img
              alt="Current vessel"
              className="shipPhoto"
              src={"http://www.ship-info.com/Vessels/" + row.photo1 + ".jpg"}
            />
          </AVITileFlex>
        );
      } else {
        return null;
      }
    });

    return (
      <div className="AppView">
        <AVIDashboard
          title="Skipsinformasjon"
          desc={
            "Informasjon om skip fra registrene NewShipRep, Fairplay (Lloyds) og Ship-Info. " +  
            "Søket er gjort basert på alle kjennetegn for det valgte skipet inkludert MMSI, IMO " +
            "nummer og kallesignal. Det kan derfor oppstå situasjoner hvor man får treff på mer enn " +
            "et skip. Dette kan for eksempel skje når flere skip har benyttet den samme MMSIen"
          }
          spacing={10}
          useFlex
        >
          <AVIRow>{images}</AVIRow>
          <AVIRow style={{ marginTop: "2em" }}>
            <AVICol>
              <h4>Fra Kystverkets skipsregister</h4>
              {/* <AVITileFlex title="Fra Kystverkets skipsregister" flex={1}> */}
              {fromNsrShip}
              {/* </AVITileFlex> */}
            </AVICol>
            {fromFairplay.length > 0 && (
              <AVICol>
                <h4>Fra Fairplay/Lloyds (supplement)</h4>
                {fromFairplay}
              </AVICol>
            )}
            {fromShipinfo.length > 0 && (
              <AVICol>
                <h4>Fra Shipinfo (supplement)</h4>
                {fromShipinfo}
              </AVICol>
            )}
            {fromCombined.length > 0 && (
              <AVICol>
                <h4>Fra kombinert skipsregister (eksperimentelt)</h4>
                {fromCombined}
              </AVICol>
            )}
          </AVIRow>
        </AVIDashboard>
      </div>
    );
  }
}

export const DbrdShip = withRouter(DbrdShipBase);

export default DbrdShip;
