import React, { useEffect, useState } from "react";

import { Button, Stack } from "react-bootstrap";
import { Abbreviate } from "../../components/Abbreviate";
import { DashboardFilter } from "../../types/DashboardFilter";
import { DataLoader } from "../../ui-components/DataLoader/DataLoader";
import { ShareDialog } from "../../ui-components/ShareDialog";
import { TimeUtils } from "../ChartUtils/TimeUtils";

export type AVIDashboardHeaderProps = {
  title: string;
  description: string;
  data: any[];
  filter: DashboardFilter;
};

export function AVIDashboardHeader({
  title,
  description,
  data,
  filter,
}: AVIDashboardHeaderProps) {
  const [lastUpdatedMessage, setLastUpdatedMessage] = useState<
    string | undefined
  >();
  
  const [shareVisible, setShareVisible] = useState<boolean>(false);

  useEffect(() => {
    DataLoader.postApi("/api/meta/most-recent", {}).then((response: any) => {
      var updated = response.data.map((d: any) => {
        var dataset = "";
        if (["ais", "nsr"].indexOf(d.dataset) > -1) {
          if (d.dataset === "ais") {
            dataset = "AIS data (f.eks. posisjoner, seilaslinjer)";
          } else if (d.dataset === "nsr") {
            dataset =
              "NewShipRep data (f.eks. skipsinformasjon, havner, seilas, avganger, ankomster, lospliktige seilas)";
          }
          return `Nyeste tilgjengelige ${dataset}: ${TimeUtils.toCompactDate(
            new Date(d.newest)
          )}`;
        } else {
          return null;
        }
      });
      const msg = updated.join(". ");
      setLastUpdatedMessage(msg);
    });
  }, []);

  return (
    <>
      <div className="d-flex w-100 gap-2 px-4">
        <div className="d-flex flex-column gap-2">
          <div className="kyv--text-heading kyv--fs-dash-title">{title}</div>
          <div className="kyv--text-body kyv--fs-body mt-auto">
            <Abbreviate maxWords={30}>{description}</Abbreviate>
          </div>
          <div className="kyv--text-body kyv--fs-body mt-auto">
            {lastUpdatedMessage}
          </div>
        </div>
        <div className="ms-auto no-print" style={{ minWidth: "100px" }}>
          <div className="d-flex flex-column gap-2">
            <Stack direction="horizontal" gap={1}>
              {/* <Button variant="outline-primary"
              className="kyv--bg-dark rounded-0 ms-auto kyv--fs-body text-uppercase"
              onClick={()=>window.print()}
              >Print <i className="bi bi-printer"></i></Button> */}
              {filter && <Button
                variant="outline-primary"
                className="kyv--bg-dark rounded-0 ms-1 kyv--fs-body text-uppercase"
                onClick={() => setShareVisible(true)}
              >
                Del <i className="vi bi-share"></i>
              </Button>}
              {/* <Button className="kyv--bg-dark rounded-0 kyv--fs-body text-uppercase"> */}
              {/* Last ned <i className="vi bi-download"></i> */}
              {/* </Button> */}
            </Stack>
          </div>
        </div>
      </div>
      <ShareDialog
        filter={filter}
        setShareVisible={setShareVisible}
        shareVisible={shareVisible}
      />
    </>
  );
}

export default AVIDashboardHeader;
