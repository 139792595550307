import "./DbrdSailedDistanceMunicipalityFairway.scss";

import React, { Component } from "react";

import crossfilter from "crossfilter2";
import PropTypes from "prop-types";
import { TimeUtils } from "../../chart-components/ChartUtils/TimeUtils";
import { BarChartOrdinal } from "../../chart-components/Charts/BarChartOrdinal";
import { BarChartStacked } from "../../chart-components/Charts/BarChartStacked";
import { RowChart } from "../../chart-components/Charts/RowChart";
import { AVICol } from "../../chart-components/Layout/AVICol";
import { AVIDashboard } from "../../chart-components/Layout/AVIDashboard";
import { AVIRow } from "../../chart-components/Layout/AVIRow";
import { CrossfilterRecord } from "../../custom";
import { SimpleTimespan } from "../../filters/SimpleDateTimeControl/SimpleTimespan";
import withRouter from "../../hocs/withRouter";
import { DataLoader } from "../../ui-components/DataLoader/DataLoader";
import { Loader } from "../../ui-components/Loader/Loader";
import { SelectMunicipality } from "../../ui-components/filter-view/FilterMap/SelectMunicipality";
import { Municipality } from "../../utils/codelists";

export type DbrdSailedDistanceMunicipalityFairwayBaseProps = {
  location: any;
};

export type DbrdSailedDistanceMunicipalityFairwayBaseState = any;

class DbrdSailedDistanceMunicipalityFairwayBase extends Component<
  DbrdSailedDistanceMunicipalityFairwayBaseProps,
  DbrdSailedDistanceMunicipalityFairwayBaseState
> {
  /**
   * The route to be used for the dashboard
   */
  static dashboardRoute = "sailed-distance-municipality-fairway";

  /**
   * The help text and filter controls to be used as initial filtering for the dashboard
   * @param {*} filter
   * @param {*} setFilter
   */
  static dashboardFilters(filter, setFilter) {
    return {
      helpMessage:
        "Dette dashboardet gjør det mulig å analysere flere år samtidig. Velg en eller flere kommuner og et tidsrom",
      controls: (
        <>
          <SimpleTimespan filter={filter} setFilter={setFilter} predefinedRanges />
          <SelectMunicipality name="municipalities" returnProperty="komm" />
        </>
      ),
    };
  }

  /**
   * The dashboard settings, i.e. map interaction filter controls, selectable layers etc.
   */
  static dashboardSettings() {
    return {
      filterControls: [],
      selectableLayer: null,
    };
  }

  /**
   * The dashboard validation function
   * @param {*} filter
   */
  static dashboardValidation(filter) {
    if (
      filter.fromTime &&
      filter.toTime &&
      Array.isArray(filter.municipalities) &&
      filter.municipalities.length > 0
    ) {
      return true;
    } else {
      return false;
    }
  }

  static propTypes = {
    location: PropTypes.shape({
      state: PropTypes.shape({
        fromTime: PropTypes.any.isRequired,
        toTime: PropTypes.any.isRequired,
        municipalities: PropTypes.arrayOf(PropTypes.object).isRequired,
      }).isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      chartData: null,
    };
    this.reportProgress = this.reportProgress.bind(this);
  }

  componentDidMount() {
    const { fromTime, toTime, municipalities } = this.props.location.state;

    try {
      if (Array.isArray(municipalities) && fromTime && toTime) {
        DataLoader.postApi(
          "/api/ais/sailed-distance/municipality-fairway",
          {
            StartDate: fromTime,
            EndDate: toTime,
            AreaIds: municipalities,
          },
          this.reportProgress
        )
          .then((jsonResponse) => {
            var success = true;

            if (!jsonResponse.success) {
              if (success) {
                success = false;
              }
            }

            if (!success) {
              throw new Error(
                "Error loading sailed distance within municipalities, fairways etc."
              );
            }

            var data = jsonResponse.data;

            data.forEach((r) => {
              let parts = r.area_id.split("_");
              r.municipality_id = parts[0];
              r.fairway_type = parts[1];
            });

            this.setState({
              fromTime,
              toTime,
              municipalityNames: municipalities.map((m) => m.label),
              chartData: crossfilter(data),
              numRecords: data.length,
            });
          })
          .catch((error) => {
            console.warn(error);
            this.setState({
              chartData: crossfilter([]),
            });
          });
      } else {
        throw new Error("Error no data returned");
      }
    } catch (error) {
      console.warn(error);
      this.setState({
        chartData: crossfilter([]),
      });
    }
  }

  reportProgress(progressData) {
    this.setState({
      progressData: progressData,
    });
  }

  render() {
    const { chartData, fromTime, toTime, municipalityNames, progressData } =
      this.state;

    if (!chartData || chartData.size() === 0) {
      return <Loader progressData={progressData} chartData={chartData} />;
    }

    var dimCount = chartData.dimension((d: CrossfilterRecord) => d.month);

    var dimArea = chartData.dimension(
      (d: CrossfilterRecord) =>
        `${Municipality["" + d.municipality_id]} (${d.fairway_type})`
    );
    var nmiByArea = dimArea
      .group()
      .reduceSum((d: CrossfilterRecord) => Math.round(d.nmi));

    var dimYear = chartData.dimension((d: CrossfilterRecord) =>
      new Date(d.month).getFullYear()
    );
    var nmiByYear = dimYear
      .group()
      .reduceSum((d: CrossfilterRecord) => Math.round(d.nmi));
    var years = nmiByYear.all().map((d: CrossfilterRecord) => d.key);

    var dimMonth = chartData.dimension(
      (d: CrossfilterRecord) => new Date(d.month).getMonth() + 1
    );
    var nmiByMonth = dimMonth
      .group()
      .reduceSum((d: CrossfilterRecord) => Math.round(d.nmi));

    var dimAisClass = chartData.dimension((d: CrossfilterRecord) => d.aisclass);
    var nmiByAisClass = dimAisClass
      .group()
      .reduceSum((d: CrossfilterRecord) => Math.round(d.nmi));

    var dimShipCategory = chartData.dimension(
      (d: CrossfilterRecord) => d.shipcategory
    );
    var nmiByShipCategory = dimShipCategory
      .group()
      .reduceSum((d: CrossfilterRecord) => Math.round(d.nmi));

    return (
      <div className="AppView">
        <AVIDashboard
          title="Utseilt distanse for kommuner etter farled"
          desc={`Utseilt distanse i nautiske mil (NM) i perioden fra ${TimeUtils.getYearMonth(
            fromTime
          )} til og med ${TimeUtils.getYearMonth(
            toTime,
            -1
          )} for kommune(ne): ${municipalityNames.join(
            ", "
          )}. Arealet som benyttes er en buffer på N meter rundt farled/biled.`}
          spacing={10}
          keyName="mmsino"
          filter={this.props.location}
          valueAccessor={(d) => d.count}
          group={dimCount.groupAll()}
          useFlex
        >
          <AVIRow>
            <BarChartOrdinal
              group={nmiByArea}
              dimension={dimArea}
              chartTitle="Utseilt distanse etter kommune"
              width={4}
              height={1}
              margins={{ left: 65 }}
              yAxisLabel="Utseilt distanse (NM)"
              filterPrefix="Kommune"
              ordering={(d: CrossfilterRecord) => d.key}
              useFlex
            />
          </AVIRow>
          <AVIRow>
            <BarChartOrdinal
              group={nmiByYear}
              dimension={dimYear}
              chartTitle="Utseilt distanse etter år"
              width={4}
              height={1}
              margins={{ left: 65 }}
              yAxisLabel="Utseilt distanse (NM)"
              filterPrefix="År"
              ordering={(d: CrossfilterRecord) => d.key}
              useFlex
            />
          </AVIRow>
          <AVIRow>
            <BarChartOrdinal
              group={nmiByMonth}
              dimension={dimMonth}
              chartTitle="Utseilt distanse (NM) etter måned i året"
              width={4}
              height={1}
              margins={{ left: 65 }}
              yAxisLabel="Utseilt distanse"
              filterPrefix="Måned"
              ordering={(d: CrossfilterRecord) => d.key}
              useFlex
            />
          </AVIRow>
          <AVIRow>
            <AVICol>
              <RowChart
                chartTitle="Utseilt distanse (NM) etter AIS-klasse"
                group={nmiByAisClass}
                dimension={dimAisClass}
                width={2}
                height={1.5}
                gap={1}
                filterPrefix="AIS-klasse"
                useFlex
              />
            </AVICol>
            <AVICol>
              <RowChart
                chartTitle="Utseilt distanse (NM) etter skipskategori"
                group={nmiByShipCategory}
                dimension={dimShipCategory}
                width={2}
                height={1.5}
                gap={1}
                filterPrefix="Skipskategori"
                useFlex
              />
            </AVICol>
          </AVIRow>
          <AVIRow>
            <BarChartStacked
              chartTitle="Utseilt distanse (NM) etter lengdegruppe og år"
              dimension={dimYear}
              xAxisTickValues={years}
              width={4}
              height={1.5}
              margins={{ top: 50 }}
              gap={2}
              filterPrefix="År"
              stackKey="length_grp"
              valueKey="nmi"
              renderLabel
              useFlex
            />
          </AVIRow>
          <AVIRow>
            <BarChartStacked
              chartTitle="Utseilt distanse (NM) etter bruttotonnasjegruppe og år"
              dimension={dimYear}
              xAxisTickValues={years}
              width={4}
              height={1.5}
              margins={{ top: 50 }}
              gap={2}
              filterPrefix="År"
              stackKey="gt_grp"
              valueKey="nmi"
              renderLabel
              useFlex
            />
          </AVIRow>
          <AVIRow>
            <BarChartStacked
              chartTitle="Utseilt distanse (NM) etter dødvektstonnasje og år"
              dimension={dimYear}
              xAxisTickValues={years}
              width={4}
              height={1.5}
              margins={{ top: 50 }}
              gap={2}
              filterPrefix="År"
              stackKey="dwt_grp"
              valueKey="nmi"
              renderLabel
              useFlex
            />
          </AVIRow>
        </AVIDashboard>
      </div>
    );
  }
}

export const DbrdSailedDistanceMunicipalityFairway = withRouter(
  DbrdSailedDistanceMunicipalityFairwayBase
);

export default DbrdSailedDistanceMunicipalityFairway;
