import "./AVIChartTile.scss";
// import "ol/ol.css";

import * as olControl from "ol/control";
import * as olInteraction from "ol/interaction";
import * as olProj from "ol/proj";

import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Blob } from "buffer";
import comp2inline from "computed-style-to-inline-style";
import * as dc from "dc";
import { saveAs } from "file-saver"; //t
import json2excel from "js2excel";
import Map from "ol/Map";
import View from "ol/View";
import WKT from "ol/format/WKT";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import Stroke from "ol/style/Stroke";
import Style from "ol/style/Style";
import { DimUtils } from "../ChartUtils/DimUtils";
import { ExportUtils } from "../ChartUtils/ExportUtils";
import { LayerSwitcherMixin } from "../Mixins/LayerSwitcherMixin";
import { useAVIDashboardContext } from "./AVIDashboardDimContext";
import { AVITileHeader } from "./AVITileHeader";
import { AVITileHeaderDropdownItem } from "./AVITileHeaderDropdownItem";

export type AVIChartTileProps = React.PropsWithChildren & {
  getChart: () => any;
  title: string;
  width?: number;
  height?: number;
  showMapWkt?: string;
  showResetFilter?: boolean;
  useFlex?: boolean;
  info?: string;
  noTools?: boolean;
  extraMenuItems?: (typeof AVITileHeaderDropdownItem)[];
};

export function AVIChartTile({
  getChart,
  title,
  width = 1,
  height = 1,
  showMapWkt,
  showResetFilter = true,
  useFlex = true,
  info = "",
  noTools = false,
  extraMenuItems = undefined,
  children,
}: AVIChartTileProps) {
  const [legendVisible, setLegendVisible] = useState<boolean>(false);
  const [mapVisible, setMapVisible] = useState<boolean>(false);
  const [, setMap] = useState<Map | undefined>(undefined);
  const legendRef = React.useRef<any>(null);
  const mapRef = React.useRef<any>(null);
  const { dims } = useAVIDashboardContext();

  const minHeight = useMemo(() => {
    return `${Math.round(200 * height)}px`;
  }, [height]);

  const clearFilter = useCallback(
    (e: any) => {
      e.stopPropagation();
      if (getChart().hasFilter()) {
        getChart().filter(null).redrawGroup();
      }
    },
    [getChart]
  );

  const copy2svg = useCallback((svg: any) => {
    comp2inline(svg.node(), {
      recursive: true,
      properties: [
        "color",
        "display",
        "font-family",
        "stroke",
        "font-size",
        "shape-rendering",
        "margin",
        "padding",
        "fill-opacity",
        "opacity",
        "fill",
      ],
    });
    return svg;
  }, []);

  const download = useCallback(
    (e: any) => {
      e.stopPropagation();

      if (getChart()) {
        var svgElem = getChart().svg();

        if (svgElem) {
          copy2svg(svgElem);

          // Get SVG
          var svgXml = svgElem
            .attr("title", encodeURIComponent(title))
            .attr("version", 1.1)
            .attr("xmlns", "http://www.w3.org/2000/svg")
            .node().parentNode.innerHTML;

          // Construct the <a> element
          var link = document.createElement("a");
          link.download = encodeURIComponent(title) + ".svg";

          // Construct the data uri
          var uri = "data:text/csv;charset=utf-8;base64," + btoa(svgXml);
          link.href = uri;
          document.body.appendChild(link);
          link.click();

          // Cleanup the DOM
          document.body.removeChild(link);
        }
      }
    },
    [copy2svg, getChart, title]
  );

  const getLegendHtml = useCallback((keys: string[], symbols: string[]) => {
    let html = "";
    for (let i in keys) {
      html += `
        <div class="avi-chart--legend-entry">
          <div class="avi-chart--legend-symbol" style="background-color: ${symbols[i]};"></div>
          <div class="avi-chart--legend-key">${keys[i]}</div>
        </div>`;
    }
    return html;
  }, []);

  const showMap = useCallback(() => {
    setMapVisible((currentlyVisible) => !currentlyVisible);
  }, []);

  // const hideMap = useCallback(
  //   () => {
  //     setMapVisible(false);
  //   }
  //   , []);

  const showLegend = useCallback(() => {
    setLegendVisible((currentlyVisible) => {
      let chart = getChart();
      if (!currentlyVisible && chart && typeof chart["legend"] === "function") {
        // Manual implementation of legend for charts that do not natively support it
        if (
          typeof chart["boxOnClick"] === "function" ||
          typeof chart["fixedBarHeight"] === "function"
        ) {
          let domain = chart.colors().domain();
          let colors = chart.colors().range();
          legendRef.current.innerHTML = getLegendHtml(domain, colors);
        } else {
          // Built-in html legend support
          chart
            .legend(
              dc.htmlLegend().container(legendRef.current).horizontal("false")
            )
            .redraw();
        }
        return true;
      } else {
        legendRef.current.innerHTML =
          "Denne diagramtypen støtter ikke tegnforklaring";
        return false;
      }
    });
  }, [getChart, getLegendHtml, legendRef]);

  // const hideLegend = useCallback(() => {
  //   setLegendVisible(false);
  // }, []);

  const downloadImage = useCallback(
    (format: string = "png") => {
      try {
        if (typeof getChart === "function" && getChart()) {
          var svgElem = getChart().svg();

          if (svgElem) {
            svgElem.attr("title", encodeURIComponent(title));
            copy2svg(svgElem);
          }

          var node = getChart().svg().node();
          var str = ExportUtils.getSVGString(node);
          ExportUtils.svgString2Image(
            str,
            getChart().width() * 2,
            getChart().height() * 2,
            format,
            (blob: Blob, size: number) => {
              saveAs(blob as any, encodeURIComponent(title) + "." + format);
            }
          );
        }
      } catch (error) {
        console.error(error);
      }
    },
    [copy2svg, getChart, title]
  );

  const downloadJPG = useCallback(
    (e: any) => {
      e.stopPropagation();
      downloadImage("jpg");
    },
    [downloadImage]
  );

  const downloadPNG = useCallback(
    (e: any) => {
      e.stopPropagation();
      downloadImage("png");
    },
    [downloadImage]
  );

  const downloadTableData = useCallback(() => {
    // const { getChart, title } = this.props;
    var chart = getChart();
    if (chart && chart.group()) {
      // Determine labels for groups, values
      var xLabel: string = "Gruppe";
      var yLabel: string = "Verdi";

      if (
        chart.hasOwnProperty("xAxisLabel") &&
        typeof chart.xAxisLabel === "function" &&
        chart.xAxisLabel()
      ) {
        xLabel = chart.xAxisLabel();
      }

      if (
        chart.hasOwnProperty("yAxisLabel") &&
        typeof chart.yAxisLabel === "function" &&
        chart.yAxisLabel()
      ) {
        yLabel = chart.yAxisLabel();
      }

      // Get rows and build a new data set for the export table
      var rows = chart.group().all();
      var data: any[] = [];
      rows.forEach((row: any) => {
        var cr: { [key: string]: any } = {};

        // Handle situations where the key has multiple dimensions
        if (Array.isArray(row.key)) {
          row.key.forEach((keyValue: any, keyIdx: number) => {
            cr[xLabel + " " + (keyIdx + 1)] = keyValue;
          });
        } else {
          cr[xLabel] = row.key;
        }

        // Handle situation where the value is an object
        if (typeof row.value === "object" && row.value !== null) {
          for (var prop in row.value) {
            cr[prop] = row.value[prop];
          }
        } else {
          cr[yLabel] = row.value;
        }

        // Add object with named keys to data array
        data.push(cr);
      });
      // Get title from the chart
      var ltitle = title.substring(0, 31);
      try {
        json2excel.json2excel({
          data: data,
          name: ltitle,
          formateDate: "yyyy/mm/dd",
        });
      } catch (e) {
        console.error("Error exporting table: " + e);
      }
    }
  }, [getChart, title]);

  const [aviChartTileSizingStyle] = useMemo(() => {
    let hws = DimUtils.getDims(dims, width, height);

    let aviChartTileSpacingStyle = {};
    let aviChartTileSizingStyle = {};

    if (useFlex === true) {
      aviChartTileSpacingStyle = {
        flex: width,
      };
      aviChartTileSizingStyle = {
        width: "100%",
        height: hws.height,
      };
    } else {
      aviChartTileSpacingStyle = {
        marginLeft: hws.spacing,
        marginTop: hws.spacing,
      };
      aviChartTileSizingStyle = {
        width: hws.width,
        height: hws.height,
      };
    }
    return [aviChartTileSizingStyle, aviChartTileSpacingStyle];
  }, [dims, height, useFlex, width]);

  useEffect(() => {
    let _map: any;
    if (showMapWkt) {
      var zoomPt = [15, 61];

      _map = new Map({
        target: mapRef.current,
        interactions: olInteraction.defaults({ mouseWheelZoom: false }),
        controls: olControl.defaults({
          attributionOptions: {
            collapsible: false,
          },
        }),
        view: new View({
          center: olProj.transform(
            [zoomPt[0], zoomPt[1]],
            "EPSG:4326",
            "EPSG:3857"
          ),
          zoom: 5,
        }),
      });

      LayerSwitcherMixin(_map);

      var format = new WKT();

      var passlineStyle = new Style({
        stroke: new Stroke({
          color: "#ff0000",
          width: 2,
        }),
      });

      var passlineFeature = format.readFeature(showMapWkt, {
        dataProjection: "EPSG:4326",
        featureProjection: "EPSG:3857",
      });

      var passlineVectorSource = new VectorSource({
        features: [passlineFeature],
      });

      var passlineLayer = new VectorLayer({
        source: passlineVectorSource,
        style: passlineStyle,
      });

      passlineLayer.setZIndex(1100);

      _map.addLayer(passlineLayer);

      _map.getView().fit(passlineVectorSource.getExtent());

      _map.getView().setZoom(_map.getView()!.getZoom()! - 2);
      setMap(_map);
    }
    return () => {
      if (_map instanceof Map) {
        _map.dispose();
      }
    };
  }, [showMapWkt]);

  return (
    <div
      style={{ flex: 1, minWidth: "20%", minHeight: minHeight }}
      className="kyv--tile-border kyv--bg-header p-3 d-flex gap-2 flex-column"
    >
      <AVITileHeader
        title={title}
        info={info}
        resetFilter={clearFilter}
        toggleLegend={showLegend}
      >
        {!noTools && (
          <>
            {showResetFilter && (
              <AVITileHeaderDropdownItem onClick={clearFilter}>
                Tilbakestill filter
              </AVITileHeaderDropdownItem>
            )}
            <AVITileHeaderDropdownItem onClick={download}>
              Last ned bilde (*.svg)
            </AVITileHeaderDropdownItem>
            <AVITileHeaderDropdownItem onClick={downloadPNG}>
              Last ned bilde (*.png)
            </AVITileHeaderDropdownItem>
            <AVITileHeaderDropdownItem onClick={downloadJPG}>
              Last ned bilde (*.jpg)
            </AVITileHeaderDropdownItem>
            <AVITileHeaderDropdownItem onClick={downloadTableData}>
              Last ned data til Excel
            </AVITileHeaderDropdownItem>
            {extraMenuItems !== undefined && <>{extraMenuItems}</>}
            <AVITileHeaderDropdownItem onClick={showLegend}>
              Vis/gjem tegnforklaring
            </AVITileHeaderDropdownItem>
            {showMapWkt && (
              <AVITileHeaderDropdownItem onClick={showMap}>
                Vis kart
              </AVITileHeaderDropdownItem>
            )}
          </>
        )}
      </AVITileHeader>
      <div className="flex-grow-1">
        {showMapWkt && (
          <div
            className="avi-tile-map"
            style={{
              ...aviChartTileSizingStyle,
              visibility: mapVisible ? "visible" : "hidden",
            }}
            ref={mapRef}
          />
        )}
        <div hidden={!legendVisible} className="avi-chart-legend">
          <div className="avi-chart-legend-element" ref={legendRef} />
        </div>
        {children}
      </div>
    </div>
  );
}

export default AVIChartTile;
