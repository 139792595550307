import React from 'react';
import { useNavigate } from "react-router-dom";

export function ModalLayoutBackButton() {
  const navigate = useNavigate();

  return (
    <div className="no-print d-flex gap-1 py-2 me-auto px-4 mb-3">
      <div>
        <i className="bi bi-arrow-left"></i>
      </div>
      <div
        className="pe-auto"
        style={{
          color: "#000667",
          fontSize: 14,
          fontFamily: "museo-sans",
          fontWeight: "300",
          textDecoration: "underline",
          cursor: "pointer",
          lineHeight: "20px",
          letterSpacing: 0.16,
          wordWrap: "break-word",
        }}
        onClick={()=>navigate(-1)}
      >
        Tilbake til tall og statistikk
      </div>
    </div>
  );
}

export default ModalLayoutBackButton;
