import React, { Component } from "react";
import * as d3 from "d3";
import * as dc from "dc";
import { BarChart } from "./BarChart";
import PropTypes from "prop-types";
import { NumUtils } from "../ChartUtils/NumUtils";
import { AVIColorScales } from "../AVIColorScales";

export class BarChartOrdinal extends Component {

  static propTypes = {
    gap: PropTypes.number,
    alwaysUseRounding: PropTypes.bool,
    centerBar: PropTypes.bool,
    outerPadding: PropTypes.number,
    barPadding: PropTypes.number,
    group: PropTypes.object,
    groupName: PropTypes.string,
    groupSelectionFunction: PropTypes.func,
    stack: PropTypes.array,
    chartTitle: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    renderLabel: PropTypes.bool,
    ordering: PropTypes.func,
    rotateXAxisLabels: PropTypes.number,
    label: PropTypes.func,
    margins: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    keyAccessor: PropTypes.func,
    useFlex: PropTypes.bool,
    colors: PropTypes.func,
    extraMenuItems: PropTypes.array,
  };

  static defaultProps = {
    gap: 2,
    round: (n) => Math.floor(n),
    alwaysUseRounding: true,
    centerBar: false,
    outerPadding: 0.5,
    barPadding: 0,
    renderLabel: true,
    rotateXAxisLabels: 0,
    label: (d) => NumUtils.integer(d.y1),
    valueAccessor: (d) => d.value,
    keyAccessor: (d) => d.key,
    useFlex: false,
    colors: AVIColorScales.colorScaleKyv2024Mono,
    extraMenuItems: undefined
  };

  render() {
    const { children = null, ...props } = this.props;

    return (
      <BarChart
        x={d3.scaleBand()}
        xUnits={dc.units.ordinal}
        barPadding={0.1}
        outerPadding={0.05}
        {...props}
      >
        {children}
      </BarChart>
    );
  }
}
