import * as d3 from 'd3';

import React, { Component } from 'react';

import { BarChart } from './BarChart';
import PropTypes from 'prop-types';
import { StackUtils } from '../ChartUtils/StackUtils';
import * as dc from 'dc';
import { AVIColorScales } from '../AVIColorScales';

export class BarChartStacked extends Component {
  static propTypes = {
    stackKey: PropTypes.string.isRequired,
    valueKey: PropTypes.string.isRequired,
    dimension: PropTypes.object,
    renderLabel: PropTypes.bool
  };

  static defaultProps = {
    renderLabel: false
  };

  render () {
    const { dimension, stackKey, valueKey, renderLabel } = this.props;
    // Get all unique types
    var types = StackUtils.getUnique(dimension, stackKey);

    var group = dimension.group().reduce(
      function add (p, v) {
        p[v[stackKey]] += parseInt(valueKey === '*count' ? 1 : v[valueKey], 10);
        return p;
      },
      function remove (p, v) {
        p[v[stackKey]] -= parseInt(valueKey === '*count' ? 1 : v[valueKey], 10);
        return p;
      },
      function init (p, v) {
        var ret = {};
        for (var i = 0; i < types.length; i++) {
          ret[types[i]] = 0;
        }
        return ret;
      }
    );

    var titleFunction = function (d) {
      return d.key + '[' + this.layer + ']: ' + d.value[this.layer];
    };

    return (
      <BarChart
        x={d3.scaleBand()}
        xUnits={dc.units.ordinal}
        barPadding={0.1}
        outerPadding={0.05}
        group={group}
        groupName={types[0]}
        groupSelectionFunction={StackUtils.selStack(types[0])}
        stack={types}
        title={titleFunction}
        clipPadding={10}
        colors={AVIColorScales.colorScaleKyv2024}
        legend={dc
          .legend()
          .x(50)
          .y(10)
          .autoItemWidth(true)
          .horizontal(true)
          .gap(5)}
        renderLabel={renderLabel}
        margins={[35, 0, 20, 50]}
        {...this.props}
      />
    );
  }
}
